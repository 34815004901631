import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import {getLocalStorageItem, setLocalStorageItem} from "../../utils/local_storage";
import setRoom from "../../utils/set_room";
import ActionTypes from "./op_management_action_types";

const initialState = {
    selectedDate: null,
    open: [],
    accepted: [],
    status: {
        session: {
            completeManualChanges: [],
            openManualChanges: []
        }
    },
    details: {},
    loadStatus: STATUS.IDLE,
    detailStatus: STATUS.IDLE,
    error: null,
    position: {
        scrollHeight: 0,
        scrollTop: 0,
        clientHeight: 0,
        clientWidth: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        offsetWidth: 0,
        devicePixelRatio: 0
    },
    opRooms: [],
    saveStatus: STATUS.IDLE,
    dateChanged: null,
    statusPublishPlan: STATUS.IDLE,
    statusNewSchedule: STATUS.IDLE,
    coreValues: {
        canvasStart: 0, // default 00:00
        canvasEnd: 23, // default 23:59
        widthPerHour: 120, // default
        currentTime: null,
        rowCount: 0,
        rowHeight: 160, // default
        planSetting: getLocalStorageItem("OpManage", "planSetting") || "both",
        roomsFilter: getLocalStorageItem("OpManage", "roomsFilter") || [], // all, plannedOps, emptyOps, disciplines
        disciplinesFilter: getLocalStorageItem("OpManage", "disciplinesFilter") || [], // set if filter = "disciplines"
        occupiedOpRooms: [],
        disciplineRoomsMapping: {}
    },
    dragStartPosition: {
        leftOffset: 0 // left offset inside of an op box on dragstart, used for DnD from search layer
    },
    searchStatus: STATUS.IDLE,
    fetchAllPatientNamesStatus: STATUS.IDLE, // used in patient names filter in the search layer
    searchOps: [],
    allOpenStatus: STATUS.IDLE,
    allOpenOps: [],
    completeStatus: STATUS.IDLE,
    discardStatus: STATUS.IDLE,
    undoStatus: STATUS.IDLE,
    requestBacklog: {},
    criticalChanges: [],
    hoveredOp: {},
    selectedOp: {},
    highlightedOp: {},
    usernames: {},
    newPosition: {},
    isFixedTime: false, // true if back/forward button on x-axis is clicked
    scrollTo: false, // if true, op-manage canvas will be scrolled to focusedOp
    simulator: {
        status: null,
        timestamp: null,
        insert: null,
        error: null
    },
    visibleDisciplines: getLocalStorageItem("OpManage", "visibleDisciplines") || [],
    isBacklogView: true,
    overlapHoveredOp: null,
    overlapClickedOp: null
};

const handleChangeDate = (state, {payload}) => ({
    ...state,
    selectedDate: payload
});
const handleLoadRequest = (state, {keepStatus}) => ({
    ...state,
    loadStatus: keepStatus ? state.loadStatus : STATUS.PENDING,
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    loadStatus: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    loadStatus: payload.loadStatus,
    error: null,
    open: payload.open,
    accepted: payload.accepted,
    status: payload.status
});

const handleSearchRequest = (state) => ({
    ...state,
    searchStatus: STATUS.PENDING,
    error: null
});

const handleSearchFailure = (state, {error}) => ({
    ...state,
    searchStatus: STATUS.REJECTED,
    error
});
const handleSearchSuccess = (state, {payload}) => ({
    ...state,
    searchStatus: STATUS.RESOLVED,
    error: null,
    searchOps: payload
});
const handleFetchAllPatientNamesRequest = (state) => ({
    ...state,
    fetchAllPatientNamesStatus: STATUS.PENDING,
    error: null
});

const handleFetchAllPatientNamesFailure = (state, {error}) => ({
    ...state,
    fetchAllPatientNamesStatus: STATUS.REJECTED,
    error
});
const handleFetchAllPatientNamesSuccess = (state) => ({
    ...state,
    fetchAllPatientNamesStatus: STATUS.RESOLVED,
    error: null
});

const handleLoadAllOpenScheduleRequest = (state) => ({
    ...state,
    allOpenStatus: STATUS.PENDING,
    error: null
});

const handleLoadAllOpenScheduleFailure = (state, {error}) => ({
    ...state,
    allOpenStatus: STATUS.REJECTED,
    error
});
const handleLoadAllOpenScheduleSuccess = (state, {payload}) => ({
    ...state,
    allOpenStatus: STATUS.RESOLVED,
    error: null,
    allOpenOps: payload
});

const handleDetailRequest = (state) => ({
    ...state,
    detailStatus: STATUS.PENDING,
    error: null,
    details: {}
});

const handleDetailFailure = (state, {error}) => ({
    ...state,
    detailStatus: STATUS.REJECTED,
    error
});

const handleDetailSuccess = (state, {payload}) => ({
    ...state,
    detailStatus: STATUS.RESOLVED,
    error: null,
    details: payload
});
const handleSavePosition = (state, {payload}) => ({
    ...state,
    position: {
        scrollHeight: payload.scrollHeight,
        scrollTop: payload.scrollTop,
        clientHeight: payload.clientHeight,
        clientWidth: payload.clientWidth,
        offsetWidth: payload.offsetWidth,
        devicePixelRatio: payload.devicePixelRatio,
        scrollWidth: payload.scrollWidth,
        scrollLeft: payload.scrollLeft
    }
});
const handleSaveOpRooms = (state, {payload}) => ({
    ...state,
    opRooms: payload
});

const handleSaveManualChangeRequest = (state, {payload}) => {
    const newOpenOps = [];
    for (let op of state.open) {
        if (op.id === payload.id) {
            op = setRoom(op, payload.location);
            op._internalTimestamps = payload.internalTimestamps;
        }
        newOpenOps.push(op);
    }

    return {
        ...state,
        saveStatus: STATUS.PENDING,
        open: newOpenOps,
        error: null
    };
};

const handleSaveManualChangeFailure = (state, {error}) => ({
    ...state,
    saveStatus: STATUS.REJECTED,
    error
});

const handleSaveManualChangeSuccess = (state, {payload, dateChanged}) => {
    return {
        ...state,
        saveStatus: STATUS.RESOLVED,
        open: payload,
        dateChanged,
        error: null
    };
};

const handleSaveStatus = (state, {payload}) => ({
    ...state,
    status: payload
});

const handlePublishPlanRequest = (state) => ({
    ...state,
    statusPublishPlan: STATUS.PENDING,
    error: null
});

const handlePublishPlanFailure = (state, {error}) => ({
    ...state,
    statusPublishPlan: STATUS.REJECTED,
    error
});

const handlePublishPlanSuccess = (state) => ({
    ...state,
    statusPublishPlan: STATUS.RESOLVED,
    error: null
});

const handleNewScheduleRequest = (state) => ({
    ...state,
    statusNewSchedule: STATUS.PENDING,
    error: null
});

const handleNewScheduleFailure = (state, {error}) => ({
    ...state,
    statusNewSchedule: STATUS.REJECTED,
    error
});

const handleNewScheduleSuccess = (state, {payload}) => ({
    ...state,
    statusNewSchedule: STATUS.RESOLVED,
    error: null
});

const handleSaveCoreValues = (state, {payload}) => {
    setLocalStorageItem("OpManage", "planSetting", payload.planSetting);
    setLocalStorageItem("OpManage", "roomsFilter", payload.roomsFilter);
    setLocalStorageItem("OpManage", "disciplinesFilter", payload.disciplinesFilter);
    return {
        ...state,
        coreValues: {...payload}
    };
};
const handleSaveLeftOffset = (state, {payload}) => ({
    ...state,
    dragStartPosition: payload
});

const handleCompleteRequest = (state) => ({
    ...state,
    completeStatus: STATUS.PENDING,
    error: null
});

const handleCompleteFailure = (state, {error}) => ({
    ...state,
    completeStatus: STATUS.REJECTED,
    error
});

const handleCompleteSuccess = (state) => ({
    ...state,
    completeStatus: STATUS.RESOLVED,
    error: null
});

const handleDiscardRequest = (state) => ({
    ...state,
    discardStatus: STATUS.PENDING,
    error: null
});

const handleDiscardFailure = (state, {error}) => ({
    ...state,
    discardStatus: STATUS.REJECTED,
    error
});

const handleDiscardSuccess = (state) => ({
    ...state,
    discardStatus: STATUS.RESOLVED,
    error: null
});

const handleUndoRequest = (state) => ({
    ...state,
    undoStatus: STATUS.PENDING,
    error: null
});

const handleUndoFailure = (state, {error}) => ({
    ...state,
    undoStatus: STATUS.REJECTED,
    error
});

const handleUndoSuccess = (state, {payload}) => ({
    ...state,
    undoStatus: STATUS.RESOLVED,
    open: payload,
    error: null
});

const handleBacklogs = (state, {payload}) => ({
    ...state,
    backlogs: payload
});

const handleGetCriticalChanges = (state, {payload}) => ({
    ...state,
    criticalChanges: payload
});
const handleClearCriticalChanges = (state) => ({
    ...state,
    criticalChanges: []
});

const handleChangeHoveredOp = (state, {payload}) => {
    const hoveredOp = {};
    if (payload) {
        hoveredOp[payload] = true;
    }
    return {
        ...state,
        hoveredOp
    };
};
const handleChangeSelectedOp = (state, {opId}) => {
    const selectedOp = {};
    if (opId) {
        selectedOp[opId] = true;
    }
    return {
        ...state,
        selectedOp
    };
};

const handleChangeHighlightedOp = (state, {payload}) => ({
    ...state,
    highlightedOp: payload
});

const handleGetUsernames = (state, {payload}) => ({
    ...state,
    usernames: payload
});

const handleSetFixedTime = (state, {payload}) => ({
    ...state,
    isFixedTime: payload
});

const handleSetScrollTo = (state, {payload}) => ({
    ...state,
    scrollTo: payload
});

const handleLoadStateRequest = (state) => ({
    ...state,
    loadStatus: STATUS.PENDING,
    error: null
});

const handleLoadStateFailure = (state, {error}) => ({
    ...state,
    loadStatus: STATUS.REJECTED,
    error
});
const handleLoadStateSuccess = (state, {payload}) => ({
    ...state,
    loadStatus: STATUS.RESOLVED,
    error: null,
    status: payload
});

const handleSimulatorAddEmergencyRequest = (state) => ({
    ...state,
    simulator: {
        status: STATUS.PENDING,
        timestamp: null,
        insert: null,
        error: null
    }
});

const handleSimulatorAddEmergencyFailure = (state, {error}) => ({
    ...state,
    simulator: {
        status: STATUS.REJECTED,
        timestamp: null,
        insert: null,
        error
    }
});

const handleSimulatorAddEmergencySuccess = (state, {payload}) => ({
    ...state,
    simulator: {
        status: STATUS.RESOLVED,
        error: null,
        ...payload
    }
});

const handleVisibleDisciplines = (state, {visibleDisciplines}) => {
    setLocalStorageItem("DayView", "visibleDisciplines", visibleDisciplines);
    return {
        ...state,
        visibleDisciplines
    };
};

const handleToggleBacklogView = (state) => ({
    ...state,
    isBacklogView: !state.isBacklogView
});

const handleOverlapHoveredOp = (state, {opId}) => ({
    ...state,
    overlapHoveredOp: opId
});
const handleOverlapClickedOp = (state, {opId}) => ({
    ...state,
    overlapClickedOp: opId
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,

    [ActionTypes.DETAIL_REQUEST]: handleDetailRequest,
    [ActionTypes.DETAIL_SUCCESS]: handleDetailSuccess,
    [ActionTypes.DETAIL_FAILURE]: handleDetailFailure,

    [ActionTypes.SAVE_MANUAL_CHANGE_REQUEST]: handleSaveManualChangeRequest,
    [ActionTypes.SAVE_MANUAL_CHANGE_SUCCESS]: handleSaveManualChangeSuccess,
    [ActionTypes.SAVE_MANUAL_CHANGE_FAILURE]: handleSaveManualChangeFailure,

    [ActionTypes.PUBLISH_REQUEST]: handlePublishPlanRequest,
    [ActionTypes.PUBLISH_SUCCESS]: handlePublishPlanSuccess,
    [ActionTypes.PUBLISH_FAILURE]: handlePublishPlanFailure,

    [ActionTypes.NEW_SCHEDULE_REQUEST]: handleNewScheduleRequest,
    [ActionTypes.NEW_SCHEDULE_SUCCESS]: handleNewScheduleSuccess,
    [ActionTypes.NEW_SCHEDULE_FAILURE]: handleNewScheduleFailure,

    [ActionTypes.CHANGE_DATE]: handleChangeDate,
    [ActionTypes.SAVE_POSITION]: handleSavePosition,
    [ActionTypes.SAVE_OP_ROOMS]: handleSaveOpRooms,
    [ActionTypes.SAVE_CORE_VALUES]: handleSaveCoreValues,
    [ActionTypes.SAVE_DRAG_START_POSITION]: handleSaveLeftOffset,

    [ActionTypes.SEARCH_REQUEST]: handleSearchRequest,
    [ActionTypes.SEARCH_SUCCESS]: handleSearchSuccess,
    [ActionTypes.SEARCH_FAILURE]: handleSearchFailure,

    [ActionTypes.FETCH_ALL_PATIENT_NAMES_REQUEST]: handleFetchAllPatientNamesRequest,
    [ActionTypes.FETCH_ALL_PATIENT_NAMES_SUCCESS]: handleFetchAllPatientNamesSuccess,
    [ActionTypes.FETCH_ALL_PATIENT_NAMES_FAILURE]: handleFetchAllPatientNamesFailure,

    [ActionTypes.COMPLETE_REQUEST]: handleCompleteRequest,
    [ActionTypes.COMPLETE_SUCCESS]: handleCompleteSuccess,
    [ActionTypes.COMPLETE_FAILURE]: handleCompleteFailure,

    [ActionTypes.DISCARD_REQUEST]: handleDiscardRequest,
    [ActionTypes.DISCARD_SUCCESS]: handleDiscardSuccess,
    [ActionTypes.DISCARD_FAILURE]: handleDiscardFailure,

    [ActionTypes.UNDO_REQUEST]: handleUndoRequest,
    [ActionTypes.UNDO_SUCCESS]: handleUndoSuccess,
    [ActionTypes.UNDO_FAILURE]: handleUndoFailure,

    [ActionTypes.GET_BACKLOGS]: handleBacklogs,

    [ActionTypes.GET_CRITICAL_CHANGES]: handleGetCriticalChanges,
    [ActionTypes.CLEAR_CRITICAL_CHANGES]: handleClearCriticalChanges,

    [ActionTypes.CHANGE_HOVEREDOP]: handleChangeHoveredOp,
    [ActionTypes.CHANGE_SELECTEDOP]: handleChangeSelectedOp,
    [ActionTypes.CHANGE_HIGHLIGHTEDOP]: handleChangeHighlightedOp,

    [ActionTypes.GET_USERNAMES]: handleGetUsernames,

    [ActionTypes.SET_FIXED_TIME]: handleSetFixedTime,

    [ActionTypes.LOAD_ALL_OPEN_SCHEDULE_REQUEST]: handleLoadAllOpenScheduleRequest,
    [ActionTypes.LOAD_ALL_OPEN_SCHEDULE_SUCCESS]: handleLoadAllOpenScheduleSuccess,
    [ActionTypes.LOAD_ALL_OPEN_SCHEDULE_FAILURE]: handleLoadAllOpenScheduleFailure,

    [ActionTypes.SET_SCROLL_TO]: handleSetScrollTo,

    [ActionTypes.SAVE_STATUS]: handleSaveStatus,

    [ActionTypes.LOAD_STATE_REQUEST]: handleLoadStateRequest,
    [ActionTypes.LOAD_STATE_SUCCESS]: handleLoadStateSuccess,
    [ActionTypes.LOAD_STATE_FAILURE]: handleLoadStateFailure,

    [ActionTypes.TRIGGER_EMERGENCY_REQUEST]: handleSimulatorAddEmergencyRequest,
    [ActionTypes.TRIGGER_EMERGENCY_SUCCESS]: handleSimulatorAddEmergencySuccess,
    [ActionTypes.TRIGGER_EMERGENCY_FAILURE]: handleSimulatorAddEmergencyFailure,

    [ActionTypes.SAVE_VISIBLE_DISCIPLINES]: handleVisibleDisciplines,

    [ActionTypes.TOGGLE_VIEW]: handleToggleBacklogView,

    [ActionTypes.SET_OVERLAP_HOVERED_OP]: handleOverlapHoveredOp,
    [ActionTypes.SET_OVERLAP_CLICKED_OP]: handleOverlapClickedOp
};
export default createReducer(initialState, handlers);
