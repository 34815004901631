// @ts-check
/**
 * @fileoverview API for op-management schedule service
 */

import axiosClient from "../../middleware/axios.middelware";
import logger from "../../utils/logger_pino";

const URL_KI_NEW = "/orchestrator/frontend/schedule/ki/new";
const URL_KI_PUBLISHED = "/orchestrator/frontend/schedule/ki/published";
const URL_STATUS = "/orchestrator/state";
const URL_POPUP = "/orchestrator/frontend/schedule/popup";
const URL_SAVE = "/orchestrator/scheduler/schedule/edit";
const URL_SCHEDULE_NEW = "/orchestrator/scheduler/schedule/new";
const URL_SCHEDULE_PUBLISH = "/orchestrator/scheduler/schedule/publish";
const URL_SCHEDULE_REAL = "/orchestrator/frontend/schedule/real";
const URL_COMPLETE = "/orchestrator/scheduler/schedule/complete";
const URL_DISCARD = "/orchestrator/scheduler/schedule/discard";
const URL_UNDO = "/orchestrator/scheduler/schedule/undo";
const URL_BACKLOGS = "/orchestrator/backlog/getEntries";
const URL_SEARCH = "/orchestrator/search/filter";

// for simulator
const URL_INSERT_EMERGENCY = "/simulator/realtime/insert_emergencies";

const errorResponse = {data: {data: [], ok: false}};

/**
 * fetch ki plans (new, published, real and status)
 * @param {string} organizationId
 * @param {string} selectedDate in ISO8061 format ex. 2020-04-23
 * @param {string} email used for logging
 * @return {Promise<Array<PromiseSettledResult<PlanBox[]>>>}
 */
async function fetchAllOpManagementSchedule(organizationId, selectedDate, email) {
    const queryParams = {
        organizationId,
        date: selectedDate
    };
    const queryParamsStatus = {
        organizationId
    };

    const [
        {status: kiNewStatus, value: kiNewValue, reason: kiNewReason},
        {status: kiPublishedStatus, value: kiPublishedValue, reason: kiPublishedReason},
        {status: stateStatus, value: stateValue, reason: stateReason},
        {status: realStatus, value: realValue, reason: realReason}
    ] = await Promise.allSettled([
        axiosClient.get(URL_KI_NEW, {params: queryParams}),
        axiosClient.get(URL_KI_PUBLISHED, {params: queryParams}),
        axiosClient.post(URL_STATUS, queryParamsStatus),
        axiosClient.get(URL_SCHEDULE_REAL, {params: queryParams})
    ]);

    // send warnings if fetch call failed
    if (kiNewStatus === "rejected") {
        logger.warn("OpManage: /ki/new call failed", {organizationId, email, reason: kiNewReason});
    }
    if (kiPublishedStatus === "rejected") {
        logger.warn("OpManage: /ki/published call failed", {organizationId, email, reason: kiPublishedReason});
    }
    if (realStatus === "rejected") {
        logger.warn("OpManage: /real call failed", {organizationId, email, reason: realReason});
    }
    if (stateStatus === "rejected") {
        logger.warn("OpManage: /state call failed", {organizationId, email, reason: stateReason});
    }

    return [
        kiNewStatus === "fulfilled" ? kiNewValue : errorResponse,
        kiPublishedStatus === "fulfilled" ? kiPublishedValue : errorResponse,
        stateStatus === "fulfilled" ? stateValue : errorResponse,
        realStatus === "fulfilled" ? realValue : errorResponse
    ];
}

/**
 * fetch all active and real ops for search
 * @param {Object} params
 * @param {String} params.organizationId
 * @param {String} params.email
 * @param {String} params.fromDate in ISO8061 format ex. 2020-04-23
 * @param {String} params.toDate in ISO8061 format ex. 2020-04-23
 * @param {String} params.patientName
 * @param {Array} params.hcservices array of hcservices ids
 * @param {Array} params.locations array of location ids
 * @param {Array} params.lockedByCustomer - locked and/or released
 * @param {Number} params.patientAgeFrom
 * @param {Number} params.patientAgeTo
 * @param {String} params.gender "female", "male", "other"
 * @param {String} params.status progress of op
 * @param {Boolean} params.onlyFirstOpOfTheDay progress of op
 * @return {Promise}
 */
function fetchSearchSchedule(params) {
    return axiosClient.post(URL_SEARCH, params);
}
/**
 * fetch all new ki plan for search
 * @param {number} organizationId
 * @return {Promise}
 */
function fetchAllOpenSchedule(organizationId) {
    const queryParams = {
        organizationId
    };
    return axiosClient.get(URL_KI_NEW, {params: queryParams});
}

/**
 * fetch status for given organizationId
 * @param {number} organizationId
 * @return {Promise}
 */
function fetchStatus(organizationId) {
    const queryParams = {
        organizationId
    };
    return axiosClient.post(URL_STATUS, queryParams);
}

/**
 * fetch new ki plan on a selected date
 * @param {number} organizationId
 * @param {string} selectedDate in ISO8061 format ex. 2020-04-23
 * @return {Promise}
 */
function fetchOpenScheduleOnSelectedDate(organizationId, selectedDate) {
    const queryParams = {
        organizationId,
        date: selectedDate
    };
    return axiosClient.get(URL_KI_NEW, {params: queryParams});
}

/**
 * fetch op details
 * @param {number} organizationId
 * @param {string} opId
 * @param {boolean} usePublishedPlan
 * @return {Promise}
 */
function fetchOpDetails(organizationId, opId, usePublishedPlan) {
    const queryParams = {
        organizationId,
        serviceRequestId: opId,
        usePublishedPlan
    };
    return axiosClient.get(URL_POPUP, {params: queryParams});
}
/**
 * fetch op details
 * @param {object} param
 * @return {Promise}
 */
function saveManualChangeAPI(param) {
    // delete internalTimestamps
    const sendData = {
        sessionId: param.sessionId,
        userEmail: param.userEmail,
        manualChanges: param.manualChanges,
        organizationId: param.organizationId
    };
    return axiosClient.post(URL_SAVE, sendData);
}

/**
 * publish new plan
 * @param {string} sessionId
 * @param {string} email
 * @return {Promise}
 */
function publishPlan(sessionId, email) {
    const params = {
        sessionId: sessionId,
        userEmail: email
    };

    return axiosClient.post(URL_SCHEDULE_PUBLISH, {...params});
}
/**
 * request new ki schedule
 * @param {number} organizationId
 * @return {Promise}
 */
function requestSchedule(organizationId) {
    const params = {
        organizationId,
        trigger: "manual"
    };

    return axiosClient.get(URL_SCHEDULE_NEW, {params: params});
}

/**
 * complete manual changes
 * @param {object} payload {userEmail, sessionId}
 * @return {Promise}
 */
function completeManualChangesAPI(payload) {
    return axiosClient.patch(URL_COMPLETE, payload);
}
/**
 * discard manual changes
 * @param {object} payload {userEmail, sessionId}
 * @return {Promise}
 */
function discardManualChangesAPI(payload) {
    return axiosClient.patch(URL_DISCARD, payload);
}
/**
 * undo manual changes
 * @param {object} payload {appointmentId, sessionId}
 * @return {Promise}
 */
function undoManualChangesAPI(payload) {
    return axiosClient.patch(URL_UNDO, payload);
}

/**
 * get backlogs
 * @param {Array} requestIds
 * @param {String} organizationId
 * @return {Promise}
 */
function getBacklogs(requestIds, organizationId) {
    const queryParams = {
        ids: requestIds,
        organizationId
    };
    return axiosClient.post(URL_BACKLOGS, queryParams);
}

/**
 * get usernames of given emails
 * @param {array} emails
 * @return {Promise}
 */
function getUsernamesAPI(emails) {
    const URL_USERNAMES = `${global.IDENTITY_SERVER_AUTHORITY}users/usernames`;

    const queryParams = {
        emails
    };
    return axiosClient.get(URL_USERNAMES, {
        params: queryParams
    });
}

/**
 * insert emergencies
 * @return {Promise}
 */
function simulatorInsertEmergenciesAPI() {
    const insert = {
        simulationId: "simulation_id1",
        priority: 10,
        count: 10
    };
    return axiosClient.post(URL_INSERT_EMERGENCY, insert);
}

export {
    fetchAllOpManagementSchedule,
    fetchOpDetails,
    saveManualChangeAPI,
    publishPlan,
    requestSchedule,
    completeManualChangesAPI,
    discardManualChangesAPI,
    undoManualChangesAPI,
    getBacklogs,
    fetchSearchSchedule,
    fetchAllOpenSchedule,
    getUsernamesAPI,
    fetchOpenScheduleOnSelectedDate,
    fetchStatus,
    simulatorInsertEmergenciesAPI
};
