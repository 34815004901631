import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {UserNotificationsOverviewContainer} from "../../components/notifications";
import Page from "../../components/shared/page";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";

const NotificationsPage = () => {
    const {t} = useTranslation();
    const organizationId = useSelector(selectCurrentOrganizationId);

    return (
        <Page
            hasNoPadding
            hasNotifications={false}
            name="notificationsPage"
            organizationId={organizationId}
            title={t("Notifications.title")}
        >
            <UserNotificationsOverviewContainer />
        </Page>
    );
};

export default NotificationsPage;
