import PropTypes from "prop-types";

// master
// https://next-data-service.visualstudio.com/13394-nextOR/_git/nextor-schematas?path=/src/models/schemasSchedules.js
const op = PropTypes.object;

const manualChanges = PropTypes.shape({
    id: PropTypes.string,
    timest: PropTypes.string,
    reasonForChange: PropTypes.array,
    userEmail: PropTypes.string,
    before: op,
    after: op
});

const scheduleOp = PropTypes.shape({
    id: PropTypes.string.isRequired,
    _patient: PropTypes.object.isRequired,
    _status: PropTypes.string.isRequired,
    _styles: PropTypes.shape({
        hexDiscipline: PropTypes.string
    }).isRequired,
    _priority: PropTypes.number.isRequired,
    _healthcareService: PropTypes.shape({
        reference: PropTypes.string,
        display: PropTypes.string
    }),
    _isEditable: PropTypes.bool
});

const statusProps = PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.string),
    session: PropTypes.shape({
        _id: PropTypes.string
    }),
    manualChanges: PropTypes.array
});

export {manualChanges, scheduleOp, statusProps};
