// @ts-check
/**
 * @typedef {("opBacklog/LOAD_OP_BACKLOG_REQUEST"|"opBacklog/LOAD_OP_BACKLOG_SUCCESS"|"opBacklog/LOAD_OP_BACKLOG_FAILURE")} OpBacklogActionTypes
 */

export const ActionTypes = {
    LOAD_OP_BACKLOG_REQUEST: "opBacklog/LOAD_OP_BACKLOG_REQUEST",
    LOAD_OP_BACKLOG_SUCCESS: "opBacklog/LOAD_OP_BACKLOG_SUCCESS",
    LOAD_OP_BACKLOG_FAILURE: "opBacklog/LOAD_OP_BACKLOG_FAILURE",
    TOGGLE_COLUMN_EXPAND: "opBacklog/TOGGLE_COLUMN_EXPAND",
    TOGGLE_FILTER_EXPAND: "opBacklog/TOGGLE_FILTER_EXPAND",
    LOAD_RESOURCE_HISTORY_REQUEST: "opBacklog/LOAD_RESOURCE_HISTORY_REQUEST",
    LOAD_RESOURCE_HISTORY_SUCCESS: "opBacklog/LOAD_RESOURCE_HISTORY_SUCCESS",
    LOAD_RESOURCE_HISTORY_FAILURE: "opBacklog/LOAD_RESOURCE_HISTORY_FAILURE",
    PUBLISH_REQUEST: "opBacklog/PUBLISH_REQUEST",
    PUBLISH_SUCCESS: "opBacklog/PUBLISH_SUCCESS",
    PUBLISH_FAILURE: "opBacklog/PUBLISH_FAILURE",
    RESET_PUBLISH_STATUS: "opBacklog/RESET_PUBLISH_STATUS"
};
