// @ts-check
/**
 * @fileoverview redux selectors for op management
 */

import {createSelector} from "reselect";

const selectOpManagement = (state) => state.opManagement;

/**
 * @param {*} state
 * @return  {DateTimeType} the date of the currently selected day
 */
const selectSelectedDate = (state) => selectOpManagement(state).selectedDate;

const selectError = (state) => selectOpManagement(state).error;
const selectLoadStatus = (state) => selectOpManagement(state).loadStatus;
const selectOpenSchedule = (state) => selectOpManagement(state).open;
const selectAcceptedSchedule = (state) => selectOpManagement(state).accepted;
const selectStatus = (state) => selectOpManagement(state).status;
/**
 * Select the details key from the opManagement slice from the store
 *
 * @param {*} state
 * @return {PopupFormat} The details of an op
 */
const selectDetails = (state) => selectOpManagement(state).details;
const selectDetailStatus = (state) => selectOpManagement(state).detailStatus;
const selectDetailError = (state) => selectOpManagement(state).detailError;
const selectPosition = (state) => selectOpManagement(state).position;

/**
 * @param {*} state
 * @return {Array<string>} the list of all the ops rooms for the organization, e.g: ["1", "2", "3"]
 */
const selectOpRooms = (state) => selectOpManagement(state).opRooms;
const selectManualChanges = (state) => {
    if (!selectStatus(state) || !selectStatus(state).session || !selectStatus(state).session.openManualChanges) return null;
    return selectStatus(state).session.openManualChanges;
};
const selectSavedManualChanges = (state) => {
    if (!selectStatus(state) || !selectStatus(state).session || !selectStatus(state).session.completeManualChanges) return null;
    return selectStatus(state).session.completeManualChanges;
};
const selectCriticalEvents = (state) => selectStatus(state).criticalEvents;
const selectDateChanged = (state) => selectOpManagement(state).dateChanged;

const selectSaveError = (state) => selectOpManagement(state).saveStat;
const selectStatusPublishPlan = (state) => selectOpManagement(state).statusPublishPlan;
const selectStatusNewSchedule = (state) => selectOpManagement(state).statusNewSchedule;
const selectCoreValues = (state) => selectOpManagement(state).coreValues;
const selectDragStartPosition = (state) => selectOpManagement(state).dragStartPosition;

const selectSearchOps = (state) => selectOpManagement(state).searchOps;
const selectSearchStatus = (state) => selectOpManagement(state).searchStatus;

const selectAllOpenOps = (state) => selectOpManagement(state).allOpenOps;
const selectAllOpenStatus = (state) => selectOpManagement(state).allOpenStatus;

const selectCompleteStatus = (state) => selectOpManagement(state).completeStatus;
const selectDiscardStatus = (state) => selectOpManagement(state).discardStatus;
const selectUndoStatus = (state) => selectOpManagement(state).undoStatus;
const selectCriticalChanges = (state) => selectOpManagement(state).criticalChanges;
const selectCriticalChangeOpIds = createSelector([selectOpManagement], (opManagementStore) =>
    opManagementStore.criticalChanges.map((item) => item.appointmentId)
);

const selectSolvedCriticalChangesIds = (state) => {
    if (!selectStatus(state).session || !selectStatus(state).session.solvedEvents) return [];
    return selectStatus(state).session.solvedEvents.map((item) => item.reference);
};

const selectSelectedOp = (state, {selectedOpId}) => {
    const selectedOp = selectOpenSchedule(state).filter((op) => op.id === selectedOpId);
    return selectedOp[0];
};
const selectIsHovered = (state, {opId}) => {
    const isHovered = selectOpManagement(state)["hoveredOp"][opId];
    return isHovered;
};
const selectIsSelected = (state, {opId}) => {
    const isSelected = selectOpManagement(state)["selectedOp"][opId];
    return Boolean(isSelected);
};

const selectHighlightedOp = (state) => selectOpManagement(state).highlightedOp;

const selectUsernames = (state) => selectOpManagement(state).usernames;

const selectScrollTo = (state) => selectOpManagement(state).scrollTo;

const selectSessionId = (state) => selectStatus(state).session?._id;
const selectSessionEditedBy = (state) => selectStatus(state).session?.editedBy;
const selectSessionPublished = (state) => selectStatus(state).session?.published;

const selectSolverEvent = (state) => selectStatus(state).solverEvent;
const selectState = (state) => selectStatus(state).state;

const selectSimulator = (state) => selectOpManagement(state).simulator;
const selectVisibleDisciplines = (state) => selectOpManagement(state).visibleDisciplines;

const selectLastPublishedSession = createSelector([selectStatus], (status) => status.lastPublishedSession || {});

const selectIsBacklogView = (state) => selectOpManagement(state).isBacklogView;

const selectOverlapHoveredOp = (state) => selectOpManagement(state).overlapHoveredOp;
const selectOverlapClickedOp = (state) => selectOpManagement(state).overlapClickedOp;

export {
    selectSelectedDate,
    selectError,
    selectLoadStatus,
    selectOpenSchedule,
    selectAcceptedSchedule,
    selectStatus,
    selectDetails,
    selectDetailStatus,
    selectDetailError,
    selectPosition,
    selectOpRooms,
    selectManualChanges,
    selectDateChanged,
    selectSaveError,
    selectStatusPublishPlan,
    selectStatusNewSchedule,
    selectCoreValues,
    selectDragStartPosition,
    selectSelectedOp,
    selectSearchOps,
    selectSearchStatus,
    selectAllOpenOps,
    selectAllOpenStatus,
    selectCompleteStatus,
    selectDiscardStatus,
    selectUndoStatus,
    selectSavedManualChanges,
    selectCriticalEvents,
    selectCriticalChanges,
    selectSolvedCriticalChangesIds,
    selectIsHovered,
    selectIsSelected,
    selectHighlightedOp,
    selectUsernames,
    selectScrollTo,
    selectCriticalChangeOpIds,
    selectSessionId,
    selectSessionEditedBy,
    selectSessionPublished,
    selectSolverEvent,
    selectState,
    selectSimulator,
    selectVisibleDisciplines,
    selectLastPublishedSession,
    selectIsBacklogView,
    selectOverlapHoveredOp,
    selectOverlapClickedOp
};
