// @ts-check
import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import {ActionTypes} from "./op_backlog_action_types";
/** @typedef {import("redux").Action} Action */

/** @type OpBacklogState */
const initialState = {
    data: [],
    error: null,
    loadingStatus: STATUS.IDLE,
    loadRHStatus: STATUS.IDLE,
    publishStatus: STATUS.IDLE,
    isColumnExpanded: {
        surgeons: false,
        anesthesia: false
    },
    isFilterExpanded: false,
    changes: {}
};

/**
 * A function to handle the loading op backlog success
 *
 * @param {OpBacklogState} state
 * @param {object} action
 * @param {array} action.payload
 * @param {Status} action.status
 * @return {OpBacklogState}
 */
const handleLoadOpBacklogSuccess = (state, {payload, status}) => ({
    ...state,
    data: payload,
    error: null,
    loadingStatus: status
});

/**
 * A function to handle the the loading op backlog request
 *
 * @param {OpBacklogState} state
 * @return {OpBacklogState}
 */
const handleLoadOpBacklogRequest = (state) => ({...state, loadingStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the loading op backlog failure
 *
 * @param {OpBacklogState} state
 * @param {object} action
 * @param {string} action.error
 * @return {OpBacklogState}
 */
const handleLoadOpBacklogFailure = (state, {error}) => ({...state, loadingStatus: STATUS.REJECTED, error});

/**
 * A function to expand or fold the column of given columnId
 *
 * @param {OpBacklogState} state
 * @param {object} action
 * @param {string} action.columnId
 * @return {OpBacklogState}
 */
const toggleColumnExpand = (state, {columnId}) => ({
    ...state,
    isColumnExpanded: {
        ...state.isColumnExpanded,
        [columnId]: !state.isColumnExpanded[columnId]
    }
});

/**
 * A function to expand or fold the column of given columnId
 *
 * @param {OpBacklogState} state
 * @return {OpBacklogState}
 */
const toggleFilterExpand = (state) => ({
    ...state,
    isFilterExpanded: !state.isFilterExpanded
});

/**
 * A function to handle the success action of fetching resource history
 *
 * @param {OpBacklogState} state
 * @param {Object} action
 * @param {Array<ResourceHistoryChangeBacklog>} action.payload
 * @param {String} action.resource
 * @param {String} action.path
 * @return {OpBacklogState}
 */
const handleLoadResourceHistorySuccess = (state, {payload, resource, path}) => ({
    ...state,
    changes: {
        ...state.changes,
        [`${resource}-${path}`]: payload
    },
    error: null,
    loadRHStatus: STATUS.RESOLVED
});

/**
 * A function to handle the request action of fetching resource history
 *
 * @param {OpBacklogState} state
 * @return {OpBacklogState}
 */
const handleLoadResourceHistoryRequest = (state) => ({...state, loadRHStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the failure action of fetching resource history
 *
 * @param {OpBacklogState} state
 * @param {object} action
 * @param {string} action.error
 * @return {OpBacklogState}
 */
const handleLoadResourceHistoryFailure = (state, {error}) => ({...state, loadRHStatus: STATUS.REJECTED, error});

/**
 * A function to handle the success action of publishing the schedule
 *
 * @param {OpBacklogState} state
 * @return {OpBacklogState}
 */
const handlePublishSuccess = (state) => ({
    ...state,
    error: null,
    publishStatus: STATUS.RESOLVED
});

/**
 * A function to handle the request action of publishing the schedule
 *
 * @param {OpBacklogState} state
 * @return {OpBacklogState}
 */
const handlePublishRequest = (state) => ({...state, publishStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the failure action of publishing the schedule
 *
 * @param {OpBacklogState} state
 * @param {object} action
 * @param {string} action.error
 * @return {OpBacklogState}
 */
const handlePublishFailure = (state, {error}) => ({...state, publishStatus: STATUS.REJECTED, error});

const handleResetPublishStatus = (state) => ({
    ...state,
    publishStatus: STATUS.IDLE
});

const handlers = {
    [ActionTypes.LOAD_OP_BACKLOG_SUCCESS]: handleLoadOpBacklogSuccess,
    [ActionTypes.LOAD_OP_BACKLOG_REQUEST]: handleLoadOpBacklogRequest,
    [ActionTypes.LOAD_OP_BACKLOG_FAILURE]: handleLoadOpBacklogFailure,
    [ActionTypes.TOGGLE_COLUMN_EXPAND]: toggleColumnExpand,
    [ActionTypes.TOGGLE_FILTER_EXPAND]: toggleFilterExpand,
    [ActionTypes.LOAD_RESOURCE_HISTORY_SUCCESS]: handleLoadResourceHistorySuccess,
    [ActionTypes.LOAD_RESOURCE_HISTORY_REQUEST]: handleLoadResourceHistoryRequest,
    [ActionTypes.LOAD_RESOURCE_HISTORY_FAILURE]: handleLoadResourceHistoryFailure,
    [ActionTypes.PUBLISH_SUCCESS]: handlePublishSuccess,
    [ActionTypes.PUBLISH_REQUEST]: handlePublishRequest,
    [ActionTypes.PUBLISH_FAILURE]: handlePublishFailure,
    [ActionTypes.RESET_PUBLISH_STATUS]: handleResetPublishStatus
};

export const opBacklogReducer = createReducer(initialState, handlers);
