// @ts-check
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import {createSelector} from "reselect";

import {selectFeSettings} from "../../components/fe_settings/fe_settings_selectors";
import getPersonIdsFromScheduleOps from "../../components/private_data/utils/get_person_ids_from_schedule_ops";
import getPractitionerIdsFromScheduleOpByCategory from "../../components/private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";

/**
 * A selector for the opBacklogList slice in the store
 *
 * @param {object} state
 * @return {OpBacklogState}
 */
export const selectOpBacklog = (state) => state.opBacklog;

/**
 * A selector for the list of op backlog
 *
 * @param {object} state
 * @return {Array<PlanBox>}
 */
export const selectOpBacklogList = (state) => selectOpBacklog(state).data;
export const selectOpBacklogListMemoized = createSelector([selectOpBacklogList], (opBacklogList) => opBacklogList);
export const selectOpBacklogById = ({id}) =>
    createSelector(selectOpBacklogList, (opBacklogList) => opBacklogList.find((op) => op.id === id));

/**
 * A selector for the list of surgery assignments, excluding if the display is null or undefined
 *
 * @param {object} state
 * @return {Array<String>}
 */
export const selectOpBacklogSurgeryNamesList = createSelector(selectOpBacklogList, (state) =>
    uniq(state?.filter((op) => op._interventions.main?.[0]?.display).map((op) => op._interventions.main[0].display))
);

/**
 * A selector for the list of all patient and practitioners
 *
 * @param {object} state
 * @return {{patientIds: Array, practitionerIds: Array}}
 */
export const selectPersonIds = createSelector(selectOpBacklogList, (state) => {
    const {patientIds, practitionerIds} = getPersonIdsFromScheduleOps(state);

    return {
        patientIds: uniq(patientIds),
        practitionerIds: uniq(practitionerIds)
    };
});

/**
 * A selector for the list of disciplines, excluding if the display is null or undefined
 *
 * @param {object} state
 * @return {Array<String>}
 */
export const selectOpBacklogHealthcareServiceList = createSelector(selectOpBacklogList, (state) =>
    uniqBy(
        state
            ?.filter((op) => op._healthcareService.reference)
            .map((op) => ({
                id: op._healthcareService.reference,
                name: op._healthcareService.display
            })),
        "id"
    )
);
/**
 * A selector for the the list of all practitioners
 *
 * @param {object} state
 * @return {OpTeam}
 */
export const selectPractitionerIdsByCategories = createSelector([selectFeSettings, selectOpBacklogList], (feSettings, opBacklogList) => {
    const ids = {};
    const {participantCategoriesForHealthcareService} = feSettings;
    for (const op of opBacklogList) {
        const mapping =
            participantCategoriesForHealthcareService.find(
                (categories) => categories.healthcareServiceId === op._healthcareService.reference
            ) || participantCategoriesForHealthcareService.find((categories) => categories.healthcareServiceId === "default");
        const team = getPractitionerIdsFromScheduleOpByCategory(op._team, mapping.categories);
        Object.entries(team).forEach(([feCategory, practitionerIds]) => {
            if (!ids[feCategory]) {
                ids[feCategory] = [];
            }
            ids[feCategory].push(...practitionerIds);
        });
    }
    const mergedKeys = participantCategoriesForHealthcareService.map((categories) => Object.keys(categories.categories)).flat();
    mergedKeys.forEach((key) => {
        ids[key] = [...new Set(ids[key])];
    });
    return ids;
});

/**
 * A selector for the filter expand
 *
 * @param {object} state
 * @return {Boolean}
 */
export const selectOpBacklogFilterExpand = (state) => selectOpBacklog(state).isFilterExpanded;

/**
 * A selector for the changes to on-hold
 *
 * @param {object} state
 * @return {Array<ResourceHistoryChangeBacklog>}
 */
export const selectOpBacklogResourceHistories = (state) => selectOpBacklog(state).changes;

/**
 * A selector for loading status
 *
 * @param {object} state
 * @return {string}
 */
export const selectLoadStatus = (state) => selectOpBacklog(state).loadingStatus;
