// @ts-check
const {FAIL_EVENTS, STATES, BLOCK_SCREEN_TYPES} = require("../../../config/event_config");

/**
 * returns a block screen type for op-backlog
 * newPlanUnsaved is not used in the op-backlog since the open manual changes will be automatically saved
 *
 * @param {string} key
 * @return {BlockScreenStype|null}
 */
export const getBlockScreenTypeForOpBacklog = (key) => {
    if (!key) {
        return null;
    }
    if (FAIL_EVENTS.includes(key)) {
        return null;
    }
    if ([STATES.PendingAutomaticUpdateEditedPlanCritical, STATES.PendingSolveEditedPlanCritical].includes(key)) {
        return "newPlanAutomatic";
    }
    return BLOCK_SCREEN_TYPES[key];
};
