import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.mobileStepper + 1,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        overflow: "auto",
        marginTop: theme.custom.headerHeight,
        marginLeft: theme.custom.menuWidth,
        padding: "3rem"
    }
}));
