// @ts-check
import {resourceHistoryPrefix} from "../../../config/api_config";
import {orchestratorPrefix} from "../../../config/api_config";
import axios from "../../middleware/axios.middelware";
import logger from "../../utils/logger_pino";

const URL_OP_BACKLOG = `${orchestratorPrefix}/frontend/schedule/ki/all`;
const URL_OP_REAL = `${orchestratorPrefix}/frontend/schedule/real`;
const URL_COMPLETE = `${orchestratorPrefix}/scheduler/schedule/complete`;
const URL_PUBLISH = `${orchestratorPrefix}/scheduler/schedule/publish`;

const URL_RESOURCE_HISTORY = `${resourceHistoryPrefix}/changes`;

const errorResponse = {data: {data: [], ok: false}};
/**
 * @param {string} organizationId
 * @param {string} email used for logging
 * @param {string} date
 * @return {Promise<Array>}
 */
export const fetchOpBacklogList = async (organizationId, email, date) => {
    // @ts-ignore
    const [{status: kiStatus, value: kiValue, reason: kiReason}, {status: realStatus, value: realValue, reason: realReason}] =
        await Promise.allSettled([
            axios.get(URL_OP_BACKLOG, {params: {organizationId, date}}),
            axios.get(URL_OP_REAL, {params: {organizationId, date}})
        ]);

    if (realStatus === "rejected") {
        logger.warn("OpBacklog - /real call failed", {organizationId, email, reason: realReason});
    }
    if (kiStatus === "rejected") {
        logger.warn("OpBacklog - /ki/all call failed", {organizationId, email, reason: kiReason});
    }

    return [kiStatus === "fulfilled" ? kiValue : errorResponse, realStatus === "fulfilled" ? realValue : errorResponse];
};

/**
 * @param {String} resource The resource to be fetched. ex. "Appointment", "manualChanges"
 * @param {String} path The path to be fetched. ex. "status/cancelled", "reasonForChange"
 * @param {Array<Array<String>>} chunkIds
 * @param {string} [changedValue]
 * @return {Promise}
 */
export const fetchResourceHistory = (resource, path, chunkIds, changedValue) => {
    const params = {};
    if (path) {
        params.path = path;
    }
    if (changedValue) {
        params.changedValue = changedValue;
    }
    return Promise.all(
        chunkIds.map((ids) => axios.get(`${URL_RESOURCE_HISTORY}/${resource}`, {params: {...params, resourceIds: ids.join(",")}}))
    );
};

/**
 * complete manual changes and publish the plan
 * @param {object} payload {userEmail, sessionId}
 * @return {Promise}
 */
export const completeAndPublishAPI = (payload) => {
    payload.combinePublish = true;
    return axios.patch(URL_COMPLETE, payload);
};

/**
 * publish new plan
 * @param {object} payload {userEmail, sessionId}
 * @return {Promise}
 */
export const publishPlanAPI = (payload) => {
    return axios.post(URL_PUBLISH, payload);
};
