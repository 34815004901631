const getActions = (handlers, isOpened, disabled) => {
    const actions = [
        {
            iconName: "AssignmentTurnedIn",
            disabled: disabled.manage,
            handler: handlers.handleOpenManage,
            translationKey: "OpManagementActions.manage",
            isOpened: isOpened.openManage
        },
        {
            iconName: "Undo",
            disabled: disabled.undo,
            handler: handlers.handleUndo,
            translationKey: "OpManagementActions.undo"
        },
        {
            iconName: "Search",
            disabled: disabled.search,
            handler: handlers.handleOpenSearch,
            translationKey: "OpManagementActions.search",
            isOpened: isOpened.openSearch
        },
        {
            iconName: "LineStyle",
            disabled: disabled.rooms,
            handler: handlers.handleOpenRooms,
            translationKey: "OpManagementActions.rooms",
            isOpened: isOpened.openRooms
        },
        {
            iconName: "MenuBook",
            disabled: disabled.legends,
            handler: handlers.handleOpenLegend,
            translationKey: "OpManagementActions.legend",
            isOpened: isOpened.openLegend
        }
    ];
    return actions;
};

export {getActions};
